.Stylesheet-div {
  color: var(--text-color);
}

.Stylesheet-label {
    display: block;
    font-variant: all-small-caps;
    line-height: 20px;
}

.Stylesheet-label-changed {
    display: block;
    font-variant: all-small-caps;
    line-height: 20px;
    font-weight: bolder;
}

.Stylesheet-input-block {
    padding-bottom: 20px;
}
