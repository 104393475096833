.Tree-element {
    color: #797878;
    line-height: 1.5em;
}

.Tree-element-selected {
    color: #2780be;
    font-weight: bold;
}

.Tree-element:hover {
    cursor: pointer;
    color: var(--hover-tree-element-color);
}

.Tree-element-root {
    font-weight: bold;
    padding: 5px 0
}

.Tree-element-modified {
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: blueviolet;
    margin-right: 5px;
    margin-bottom: 3px;
}

/*
.Tree-element-modified:hover {
    height: 15px;
    width: 15px;
    transition: 300ms ease-in-out all;
    margin-bottom: -2px;
}

.Tree-element-modified:hover::before,
.Tree-element-modified:hover::after {
    content: " ";
    height: 20px;
    width: 2px;
    background-color: #e2d02b;
}
.Tree-element-modified:hover::before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.Tree-element-modified:hover::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
*/
