:root {
    @media (prefers-color-scheme: dark) {
        --main-bg-color: black;
        --text-color: white;
        --hover-tree-element-color: white
    }

    @media (prefers-color-scheme: light) {
        --main-bg-color: white;
        --text-color: black;
        --hover-tree-element-color: black

    }
}

input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: rebeccapurple;
    margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

body {
    font-family: Helvetica, 'sans-serif';
}

.App-column {
    border-right: 1px solid lightgrey;
    max-height: 100vh;
    overflow: scroll;
    color: var(--text-color);
}

.StyleInput {
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    width: 500px;
    margin-left: -250px;
    height: 250px;
}

.StyleInput > textarea {
    width: 100%;
    height: 200px;
    font-family: monospace;
}
