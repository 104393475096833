.Editor-div {
    background-color: var(--main-bg-color);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-basis: content;
}

.Editor-div > div {
    padding-left: 10px;
    padding-right: 10px;
}
